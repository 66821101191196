body {
  margin: 0;
  font-family: 'Lato', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f7f7 !important;
}

div{
  box-sizing: border-box;
}

code {
  font-family: 'Lato', sans-serif !important;
}
